<template>
  <div class="tableList">
    <el-table
      ref="multipleTable"
      :data="dataSource"
      v-loading="loading"
      element-loading-text="加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.7)"
      style="width: 100%"
      height="5.20833vw"
      :row-class-name="rowClassName"
      @select="handleSelect"
      @select-all="handleSelectAll"
      @selection-change="selectRowChange"
      v-table-auto-height
    >
      <el-table-column
        v-if="showCheckBox"
        type="selection"
        width="50"
        align="center"
      ></el-table-column>
      <el-table-column
        v-if="showXh"
        type="index"
        label="序号"
        width="50"
        align="center"
      ></el-table-column>
      <template v-for="item in columns">
        <slot v-if="item.slotName" :name="item.slotName"></slot>
        <el-table-column
          v-if="!item.slotName"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          :align="item.align || 'left'"
          :fixed="item.fixed"
          :class-name="item.className"
          :formatter="item.formatter"
          :show-overflow-tooltip="item.showTooltip"
          :min-width="item.minWidth"
          :key="item.prop"
          :sortable="item.sortable || false"
        >
        </el-table-column>
      </template>
    </el-table>
    <div v-if="pagination && ipagination.total > 0" class="l-pages">
      <div class="total-num">
        显示第{{ showNum }}条记录，共{{ ipagination.total }}条记录
      </div>
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="current"
        @size-change="handleSizeChange"
        :page-size="ipagination.size"
        :page-sizes="ipagination.pageSizes"
        layout="prev, pager, next, sizes"
        :total="ipagination.total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  props: {
    // 列数据
    columns: {
      type: Array,
      default: () => {
        return [];
      },
    },
    // 列表数据
    dataSource: {
      type: Array,
      default: () => {
        return [];
      },
    },
    // 加载状态
    loading: {
      type: Boolean,
      default: false,
    },
    // 序号展示
    showXh: {
      type: Boolean,
      default: true,
    },
    // 多选框展示
    showCheckBox: {
      type: Boolean,
      default: false,
    },
    // 分页展示
    pagination: {
      type: Boolean,
      default: true,
    },
    // 分页数据
    ipagination: {
      type: Object,
      default() {
        return {
          num: 1,
          size: 10,
          pageSizes: [1, 20, 50, 100, 500],
          total: 0,
        };
      },
    },
    rowClassName: {
      type: [String, Function],
      default: "",
    },
  },
  data() {
    return {
      current: 1,
      selectArrs: [],
      currentIndex: 1,
      pageSize: 10,
    };
  },
  watch: {
    ipagination: {
      deep: true,
      handler(val) {
        this.current = val.num;
        this.currentIndex = val.num;
      },
    },
  },
  computed: {
    showNum() {
      let start = (this.current - 1) * this.ipagination.size + 1;
      let end =
        this.current * this.ipagination.size > this.ipagination.total
          ? this.ipagination.total
          : this.current * this.ipagination.size;
      return this.ipagination.total === 0
        ? this.ipagination.total
        : `${start}-${end}`;
    },
  },
  methods: {
    /**
     * 点击切换页码
     */
    handleCurrentChange(val) {
      this.current = val;
      this.currentIndex = val;
      this.$emit("current-change", val);
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.$emit("size-change", size);
    },
    /**
     * 多选
     */
    selectRowChange(arr) {
      this.$emit("selection-change", arr);
    },
    handleSelect(selection, row) {
      this.$emit("select", selection, row);
    },
    handleSelectAll(arr) {
      this.$emit("select-all", arr);
    },
  },
};
</script>
<style scoped lang="scss">
.tableList {
  // 表格
  margin: auto;
}
.l-pages {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  .total-num {
    font-family: SourceHanSansCN-Regular;
    font-size: 14px;
    color: #7c7c7c;
  }
  .el-pagination {
    padding: 0;
    .el-pagination__sizes {
      margin: 0 0 0 8px;
    }
  }
  .el-pagination .el-select .el-input {
    .el-input__icon {
      right: 20px;
    }
    input {
      width: 100px;
      height: 32px;
      border: 1px solid #e8ecef;
      opacity: 1;
      border-radius: 4px;
      padding: 3px 10px !important;
    }
  }
  .btn-prev,
  .btn-next,
  .el-pager li {
    width: 32px;
    height: 32px;
    border-radius: 6px;
    border: 1px solid #e8ecef !important;
  }
  .btn-next {
    margin-left: 8px;
  }
  .el-pager li {
    line-height: 30px;
    border-radius: 6px;
    font-size: 14px;
    color: #6a6d77;
    margin-left: 8px;
    &:hover {
      background-color: #004ea2 !important;
      border: 1px solid #004ea2 !important;
      color: #ffffff;
    }
  }
  .el-pager li.active {
    background-color: #004ea2 !important;
    border: 1px solid #004ea2 !important;
    color: #ffffff;
  }
}
</style>
